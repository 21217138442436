import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useRouter } from 'next/router'
import queryString from 'query-string'

import DocFinderHeader from './components/DocFinderHeader'
import DocFinderFilters from './components/DocFinderFilters'
import DocFinderMap from './components/DocFinderMap'
import DocFinderSearchList from './components/DocFinderSearchList'

import useStyles from './DocFinder.styles'

import { DocSearchFields, DocSearchFiltersQuery, DocSearchFilterState } from '../../../../@types/DocSearch'
import { DocSearchResultItem, DocSearchQuery } from '../../../../@types/DocSearch'

import { docSearch } from '../../../../utils/api'
import DocFinderDrawerV2 from './components/DocFinderDrawer/DocFinderDrawer.v2'
import DocFinderSearchItemOpen from './components/DocFinderSearchItemOpen'
import { useMediaQuery } from '@material-ui/core'
import theme from '../../../theme'
import { backendFilterIds, docSearchFilters } from './docSearchFilters'
import useMatomoDocSearchTracking from '../../../hooks/useMatomoDocSearchTracking'

type Props = {
  data: DocSearchFields
  mapsKey?: string
}

const DocFinder: React.FC<Props> = ({ data, mapsKey }) => {
  const router = useRouter()

  const query = queryString.parse((typeof window !== 'undefined' && window?.location?.search) || '')

  const initialPostalCode = Array.isArray(query.search) ? query?.search?.join('') || '' : query?.search || ''

  const postalCodeFromQuery = router?.query?.search || ''
  const productsFromQuery = (
    (router?.query?.product as string | undefined) || (query?.product as string | undefined)
  )?.split(',')

  const initialRadius: number = parseInt(data?.radiusOptions[0].value)
  const [radius, setRadius] = useState<number>(initialRadius)

  const mapContainerRef = useRef<HTMLDivElement | null>(null)
  const [mapHeight, setMapHeight] = useState(0)

  const [searchFilters, setSearchFilters] = useState<DocSearchFilterState[]>(
    docSearchFilters.map((item) => {
      let isSelected = true

      if (item.frontEndGroup === 'Praxis') {
        isSelected = false
      } else if (item.frontEndGroup === 'Produkte' && productsFromQuery && productsFromQuery?.length > 0) {
        isSelected = Boolean(productsFromQuery?.includes(item.id || ''))
      }

      return {
        ...item,
        selected: isSelected, //item.frontEndGroup === 'Praxis' ? false : true, // all filters are selected by default except the Praxis group
      }
    })
  )

  const [postalCode, setPostalCode] = useState<string>('')
  const [locations, setLocations] = useState<Partial<DocSearchResultItem>[]>([])
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [apply, setApply] = useState<boolean>(false)
  const [hoveredPinSlug, setHoveredPinSlug] = useState<string>('')
  const [clickedPinSlug, setClickedPinSlug] = useState<string>('')
  const [totalNumberOfClinics, setTotalNumberOfClinics] = useState<number>(0)

  const prevRadius = useRef<number>(initialRadius)

  const ResultsDrawerAnchorRef = useRef<HTMLDivElement>(null)

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true)

  const { trackDocSearch } = useMatomoDocSearchTracking()

  useEffect(() => {
    if (mapContainerRef.current) {
      setMapHeight(mapContainerRef.current.offsetHeight)
    }
  }, [])

  useEffect(() => {
    if (postalCodeFromQuery) {
      if (postalCodeFromQuery !== postalCode) {
        setPostalCode(postalCodeFromQuery as string)
      }

      getClinics({
        zipCode: initialPostalCode,
        radius: 25,
        ...selectedFiltersQuery,
      })

      fireAnalyticsEvent()
    }
  }, [])

  useEffect(() => {
    const refetch = async () => {
      if (postalCodeFromQuery) {
        setApply(false)

        setPostalCode(postalCodeFromQuery as string)

        await getClinics({
          zipCode: initialPostalCode,
          radius: 25,
          ...selectedFiltersQuery,
        })

        setApply(true)

        fireAnalyticsEvent()
      }
    }
    refetch()
  }, [postalCodeFromQuery])

  useEffect(() => {
    const radiusChanged = radius !== prevRadius.current

    const hasLocations = locations?.length > 0

    const triggerSearch = hasLocations && radiusChanged

    if (radiusChanged) {
      prevRadius.current = radius
    }

    if (triggerSearch) {
      handleSearch()
    }
  }, [radius, locations])

  const getClinics = async (query: DocSearchQuery) => {
    const { results: clinics } = await docSearch(query)
    const alphabeticallySortedClinics = clinics?.sort((a, b) => a.title.localeCompare(b.title))
    setLocations(alphabeticallySortedClinics)
    setTotalNumberOfClinics(alphabeticallySortedClinics?.length)
  }

  const selectedFiltersQuery = useMemo(() => {
    const selectedFilters = searchFilters.filter((item) => item.selected && !item.isSelectingTheWholeGroup)
    return selectedFilters.reduce(
      (prev, item) => {
        backendFilterIds.forEach((filterName) => {
          const filter = filterName as keyof DocSearchFiltersQuery
          const filterIds = item[filter]
          const prevIds = prev[filter]
          if (typeof filterIds !== 'undefined' && typeof prevIds !== 'undefined') {
            prev[filter] = [...prevIds, ...filterIds]
          }
        })
        return prev
      },
      {
        products: [],
        treatments: [],
        areas: [],
        category: [],
        features: [],
        specialty: [],
      } as DocSearchFiltersQuery
    )
  }, [searchFilters])

  const handleSearch = async () => {
    setIsSearching(true)
    setApply(false)

    fireFraud0()

    trackDocSearch(postalCode)

    try {
      if (postalCode?.length > 0) {
        await getClinics({
          zipCode: postalCode,
          radius,
          ...selectedFiltersQuery,
        })
      } else {
        await getClinics({
          zipCode: '',
          radius: 800,
          ...selectedFiltersQuery,
        })
      }

      fireAnalyticsEvent()

      setApply(true)
    } catch (error) {
      console.warn('error', error)
    }

    setIsSearching(false)
  }

  const fireAnalyticsEvent = () => {
    /** Google Analytics Event */
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push({ event: 'Arztsuche', Suche_PLZ: `${searchFilters.join()}/${postalCode}` })
    }
  }

  const fireFraud0 = () => {
    if (typeof window !== 'undefined') {
      window.fraud0 = window.fraud0 || []
      window.fraud0.push([1])
    }
  }

  const handleChangePostalCode = (e: React.ChangeEvent<HTMLInputElement>) => setPostalCode(e?.target.value)

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxId = e?.target.value
    const thisSearchFilter = searchFilters.find((item) => item.frontEndName === checkboxId)

    if (!thisSearchFilter) {
      console.warn('filter not found:', checkboxId)
      return
    }

    if (thisSearchFilter.isSelectingTheWholeGroup) {
      if (thisSearchFilter.selected) {
        // Deselect all products in the group
        setSearchFilters((prev) =>
          prev.map((item) => {
            if (item.frontEndGroup === thisSearchFilter.frontEndGroup) {
              return { ...item, selected: false }
            }
            return item
          })
        )
      } else {
        // Select all products in the group
        setSearchFilters((prev) =>
          prev.map((item) => {
            if (item.frontEndGroup === thisSearchFilter.frontEndGroup) {
              return { ...item, selected: true }
            }
            return item
          })
        )
      }
    } else {
      if (thisSearchFilter.selected) {
        // We deselect the filter and make sure to remove the "ALL" option
        setSearchFilters((prev) =>
          prev.map((item) => {
            if (
              item.frontEndName === thisSearchFilter.frontEndName ||
              (item.frontEndGroup === thisSearchFilter.frontEndGroup && item.isSelectingTheWholeGroup)
            ) {
              return { ...item, selected: false }
            }
            return item
          })
        )
      } else {
        setSearchFilters((prev) => {
          // We select the filter
          const updatedPrev = prev.map((item) => {
            if (item.frontEndName === thisSearchFilter.frontEndName) {
              return { ...item, selected: true }
            }
            return item
          })
          // And check whether all other filters in the group are selected (possible exception is the "ALL" option), in which case, add the "ALL" option
          const allFiltersInGroupSelected = updatedPrev
            .filter((item) => item.frontEndGroup === thisSearchFilter.frontEndGroup)
            ?.every((item) => item.selected || item.isSelectingTheWholeGroup)

          if (allFiltersInGroupSelected) {
            return updatedPrev.map((item) => {
              if (item.frontEndGroup === thisSearchFilter.frontEndGroup) {
                return { ...item, selected: true }
              }
              return item
            })
          }
          return updatedPrev
        })
      }
    }
  }

  const _onHoverPin = (slug: string) => {
    if (clickedPinSlug !== '') {
      return
    }
    setHoveredPinSlug(slug)
  }

  const _onClickPin = (slug: string) => {
    setHoveredPinSlug('')
    if (slug === clickedPinSlug) {
      setClickedPinSlug('')
    } else {
      setClickedPinSlug(slug)
    }
  }

  const handleQuickSearch = async (search: string) => {
    setIsSearching(true)
    setApply(false)

    setPostalCode(search)

    await getClinics({
      zipCode: search,
      radius: 25,
      ...selectedFiltersQuery,
    })

    setApply(true)
    setIsSearching(false)
  }

  const handleResetResults = () => {
    setLocations([])
    setPostalCode('')
  }

  const isResultOpen = !!clickedPinSlug
  const classes = useStyles()

  const searchResultOpen = isResultOpen ? (
    <DocFinderSearchItemOpen
      clickedPinSlug={clickedPinSlug}
      onClickPin={_onClickPin}
      results={locations}
      isOpen={!!clickedPinSlug}
    />
  ) : null

  const sidebarContent =
    locations?.length > 0 ? (
      <DocFinderSearchList
        results={locations}
        resetResults={handleResetResults}
        numberOfClinics={totalNumberOfClinics}
        onHoverPin={_onHoverPin}
        onClickPin={_onClickPin}
        hoveredPinSlug={hoveredPinSlug}
        clickedPinSlug={clickedPinSlug}
        setIsDrawerOpen={setIsDrawerOpen}
        filterProps={{
          searchFilters,
          handleFilterChange,
          radius,
          setRadius,
          setPostalCode: handleQuickSearch,
          onSearch: handleSearch,
          isSearching,
        }}
      />
    ) : (
      <>
        <div className={classes.sidebarSearchArea}>
          <DocFinderHeader
            onChangePostalCode={handleChangePostalCode}
            postalCode={postalCode}
            onSearch={handleSearch}
          />
        </div>
        <DocFinderFilters
          searchFilters={searchFilters}
          handleFilterChange={handleFilterChange}
          radius={radius}
          setRadius={setRadius}
          setPostalCode={handleQuickSearch}
          onSearch={handleSearch}
          isSearching={isSearching}
        />
      </>
    )

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))

  return (
    <div className={classes.root} ref={ResultsDrawerAnchorRef}>
      <div className={classes.mainSearchBarArea}>
        <DocFinderHeader onChangePostalCode={handleChangePostalCode} postalCode={postalCode} onSearch={handleSearch} />
      </div>
      <div className={classes.sidebarWithMapArea}>
        <div className={classes.sidebarArea} id="sidebarArea">
          {sidebarContent}
        </div>
        {isLargeScreen ? (
          <div
            className={classes.resultsSlidingWrapper}
            style={{
              width: isResultOpen ? 400 : 0,
            }}
          >
            {searchResultOpen}
          </div>
        ) : null}
        <div className={classes.mapArea} id="scroll-to-map" ref={mapContainerRef}>
          <DocFinderMap
            locations={locations}
            clickedPinSlug={clickedPinSlug}
            onHoverPin={_onHoverPin}
            onClickPin={_onClickPin}
            setIsDrawerOpen={setIsDrawerOpen}
            hoveredPinSlug={hoveredPinSlug}
            radius={radius}
            apply={apply}
            mapsKey={mapsKey}
            mapHeight={mapHeight}
          />
        </div>
      </div>
      <DocFinderDrawerV2
        container={null}
        clickedPinSlug={clickedPinSlug}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        shorterDrawer={locations?.length > 0}
      >
        {clickedPinSlug ? searchResultOpen : sidebarContent}
      </DocFinderDrawerV2>
    </div>
  )
}

export default DocFinder
