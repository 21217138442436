import React, { useEffect, useState } from 'react'
import GoogleMap, { Coords } from 'google-map-react'
import useCookie from 'react-use-cookie'

import { DocSearchResultItem } from '../../../../../../@types/DocSearch'

import Pin from './components/Pin'

import styles from './DocFinderMap.styles'
import Consent from './components/Consent/Consent'

// This function centers the clicked pin in the upper third of the map (so that it is not hidden by the drawer)
const adjustLatitude = (lat: number, zoom: number, mapHeight: number, offsetFactor: number): number => {
  console.log('adjustLatitude', lat, zoom, mapHeight, offsetFactor)
  const metersPerPx = (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom)
  const offsetLat = (offsetFactor * mapHeight * metersPerPx) / 111111
  return lat - offsetLat
}

const defaultCoordinates = {
  center: {
    lat: 51.1657,
    lng: 10.4515,
  },
  zoom: 7,
}

type Props = {
  locations: Partial<DocSearchResultItem>[]
  clickedPinSlug: string
  hoveredPinSlug: string
  onHoverPin: (slug: string) => void
  onClickPin: (slug: string) => void
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
  radius: number
  apply: boolean
  mapsKey?: string
  mapHeight: number
}

const DocFinderMap: React.FC<Props> = ({
  locations,
  clickedPinSlug,
  onHoverPin,
  onClickPin,
  setIsDrawerOpen,
  hoveredPinSlug,
  radius,
  apply,
  mapsKey,
  mapHeight,
}) => {
  const [coordinates, setCoordinates] = useState<Record<string, any>>(defaultCoordinates)

  const getBaseLog = (x: number, y: number) => Math.log(y) / Math.log(x)

  const _onBoundsChange = (center: Coords, zoom: number): void =>
    setCoordinates({
      center: {
        lat: center.lat,
        lng: center.lng,
      },
      zoom,
    })

  useEffect(() => {
    if (apply) {
      setCoordinates((prevState) => ({
        ...prevState,
        center: {
          lat: locations?.[0]?.lat ? parseFloat(locations?.[0]?.lat) : defaultCoordinates.center.lat,
          lng: locations?.[0]?.lng ? parseFloat(locations?.[0]?.lng) : defaultCoordinates.center.lng,
        },
        zoom:
          locations?.length > 0 ? parseInt(getBaseLog(2, 40000 / (radius / 2)).toFixed(0)) : defaultCoordinates.zoom,
      }))
    }
  }, [apply])

  useEffect(() => {
    if (clickedPinSlug) {
      const location = locations?.find((location) => location?.slug === clickedPinSlug)
      const zoom = parseInt(getBaseLog(2, 40000 / (0.5 / 2)).toFixed(0))

      setCoordinates((prevState) => ({
        ...prevState,
        center: {
          lat: location?.lat
            ? adjustLatitude(parseFloat(location?.lat), zoom, mapHeight, 0.33)
            : defaultCoordinates.center.lat,
          lng: location?.lng ? parseFloat(location?.lng) : defaultCoordinates.center.lng,
        },
        zoom,
      }))
    } else {
      setCoordinates((prevState) => ({
        ...prevState,
        zoom: parseInt(getBaseLog(2, 40000 / (radius / 2)).toFixed(0)),
      }))
    }
  }, [clickedPinSlug])

  useEffect(() => {
    if (!apply && locations?.length > 0) {
      setTimeout(() => {
        setCoordinates((prevState) => ({
          ...prevState,
          center: {
            lat: locations?.[0]?.lat ? parseFloat(locations?.[0]?.lat) : defaultCoordinates.center.lat,
            lng: locations?.[0]?.lng ? parseFloat(locations?.[0]?.lng) : defaultCoordinates.center.lng,
          },
          zoom: parseInt(getBaseLog(2, 40000 / (radius / 2)).toFixed(0)),
        }))
      }, 100)
    }
  }, [locations])

  // Map Consent management - SessionStorage and Cookie
  const [mapConsentLoading, setMapConsentLoading] = useState(true)
  const [mapConsentSession, setMapConsentSession] = useState('0')
  const [mapConsentCookie, setMapConsentCookie] = useCookie('mapConsentCookie', '0')
  useEffect(() => {
    const mapConsentSession = sessionStorage.getItem('mapConsentSession') ?? '0'
    setMapConsentSession(mapConsentSession)
    setMapConsentLoading(false)
  }, [])
  const handleCookieConsent = (withCookie: boolean) => {
    setMapConsentSession('1')
    sessionStorage.setItem('mapConsentSession', '1')
    if (withCookie) {
      setMapConsentCookie('1', {
        days: 180,
        SameSite: 'Strict',
        Secure: true,
      })
    }
  }
  const mapConsent = mapConsentSession === '1' || mapConsentCookie === '1'

  return mapConsentLoading ? null : mapConsent ? (
    mapsKey ? (
      <GoogleMap
        bootstrapURLKeys={{ key: mapsKey }}
        zoom={coordinates.zoom}
        center={coordinates.center}
        onChange={({ center, zoom }) => _onBoundsChange(center, zoom)}
        options={{
          styles,
        }}
      >
        {locations?.map((location: Partial<DocSearchResultItem>, index: number) => (
          <Pin
            key={index}
            location={location}
            onHoverPin={onHoverPin}
            onClickPin={onClickPin}
            clickedPinSlug={clickedPinSlug}
            hoveredPinSlug={hoveredPinSlug}
            setIsDrawerOpen={setIsDrawerOpen}
            lat={location?.lat}
            lng={location?.lng}
          />
        ))}
      </GoogleMap>
    ) : null
  ) : (
    <Consent handleCookieConsent={handleCookieConsent} />
  )
}

export default DocFinderMap
